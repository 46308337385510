/* Color Palette */

:root {
  //colors and accents
  --primary: #00c8be; //primary color
  --light-primary: #43D9B3; //lighter shade of primary color
  --accent: #ff6500; //accent color to highlight certain actions
  --dark-accent: #c64b00; //darker shade of accent color
  --peach-accent: #FF7575; //rose accent color to fill palette
  --light-peach-accent: #FFEADC;
  --violet-accent: #AC228F; //violet accent color to fill palette
  --yellow-highlight: #ffb400;

  //neutrals
  --very-light-grey: #f1f1f1; //to highlight hover over white background
  --light-grey: #e9efef; //for everything grey-ish
  --medium-light-grey: #cbcbcb; //to highlight hover over light-gray background
  --medium-grey: #6c6c6c; //
  --dark-grey: #4d4d4d; //used for main text or for text-fields to get users attention
  --very-dark-grey: #313131; //used for text and interaction elements that need more contrast than the regular dark grey
  --night-grey: #0D0E13; //used for dark backgrounds and dark content sections

  //variant neutral colors
  --very-light-teal: #F4FAFB; //used for sections with colored backgrounds
  --light-teal: #D2FFFC;
  --dark-teal: #0097A7;
  --very-dark-teal: #005c65;
  --dark-marine: #0A2540; //used for header texts
  --very-dark-marine: #041E39; //used for dark texts when neutral grey does not fit

  //pastels
  --pastel-accent: #ff9653;
  --pastel-pink: #EE6B85;
  --pastel-violet: #9A84FF;

  //light pastels
  --light-pastel-accent: #fff1e8;
  --light-pastel-pink: #ffeff2;
  --light-pastel-violet: #eeeaff;
}
