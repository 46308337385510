section{
  max-width: 100dvw;
}

.header-section {
  padding: 8rem 0 2rem;

  .regular-text {
    font-weight: 400;
  }
}

.header-image-container {
  position: absolute;
  right: 0;
  max-width: 620px;
  width: 100%;
}

.mobile-header-image {
  position: absolute;
  bottom: 0;
  right: 2rem;
  max-height: 95%;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .header-section {
    padding: 8rem 3rem 2rem;
  }

  .mobile-header-image {
    max-height: calc(100% - 6rem);
    right: 0;
  }
}

@media (max-width: 600px) {
  .mobile-header-image {
    right: -2rem;
    max-height: calc(100% - 6rem)
  }

  .header-section {
    padding: 7rem 1rem 1rem;

    .regular-text {
      font-weight: 400;
    }
  }
}

.header-image{
  max-width: 100%;
  position:absolute;
  top: 2rem;
  left: 4rem;
}

.header-image-card {
  border-radius: 2rem;
  object-position: center;
  object-fit: cover;
}

.header-gradient {
  background: linear-gradient(180deg, #72C5BF -57.14%, rgba(114, 197, 191, 0.22) 39.05%, rgba(114, 197, 191, 0.00) 100%);
}

.stock-girl-img{
  position: absolute;
  bottom: 0;
  right: 1rem;
  z-index: 2;
  max-width: 280px;
}

.image-100-dvw {
  width: 100dvw;
}

.frame-img{
  position: absolute;
  bottom: 1.5rem;
  right: 2.5rem;
  z-index: 1;
  max-width: 180px;
}

.filter-img{
  position: absolute;
  left: -20%;
  top: -3rem;
  z-index: 1;
  max-width: 200px;
  filter: drop-shadow(0 4px 32px rgba(0,200,190,0.25));
}

@media(max-width: 600px){
  .stock-girl-img{
    max-width: 200px;
  }
  .filter-img{
    max-width: 150px;
    top: 2rem;
    left: -3rem;
  }
}

.step1-image-wrapper{
  width: 380px;
  height: 320px;
}

.arrow-img{
  max-width: 150px;
}

.arrow-img-flip{
  max-width: 150px;
  transform: scaleX(-1);
}

.usp-info{
  font-size: 1.5rem;
  font-weight:500;
}

.usp-number{
  font-size: 3rem;
  font-weight: 900;
}

.usp p {
  color: var(--medium-grey);
}

.horizontal-scrollable-container{
  overflow-x: auto;
}

.hidden{
  opacity: 0;
}

.partner-logo{
  max-height: 50px;
  transform: translateX(-20%);
  opacity: 0;
  width: 15%;
  img{
    max-height:50px;
    width:100%;
    object-fit: contain;
  }
  transition: 0.3s ease-in-out;

  &:hover{
    filter: grayscale(0%) brightness(1) !important;
  }
}

@media (max-width: 600px){
  .partner-logo{
    max-height: 35px;
    width: 50% !important;
    img{
      max-height: 35px;
      width:100% !important;
      object-fit: contain;
    }
  }
}

.usp{
  //transform: rotate(240deg) scale(0.6);
  //opacity: 0;
  //filter: blur(0px);
}

.show{
  animation: fadeIn 0.5s forwards ease-in;
}

.slideInBottom{
  animation: slideInFromBelow 0.5s forwards ease-in;
}

.bounceIn{
  animation: bounceIn 0.4s forwards ease-in-out;
}

.slideInLeft{
  animation: slideInLeftGrayScale 0.4s forwards ease-in-out;
}

.revealFromTop{
  animation-delay: 0.2s !important;
  animation: revealFromTop 0.4s forwards ease-in-out;
}

.jobSlide{
  div{
    opacity: 0;
    transform: translateX(-100%);
    animation: slideInFromLeft 0.5s forwards ease-in;
  }
}

.jobSlide.div:nth-child(2){
  animation-delay: 0.2s;
}

.jobSlide.div:nth-child(3){
  animation-delay: 0.4s;
}

.highlight, .highlight-white{
  background: var(--primary);
}

.highlight-show{
  animation: highlightReverseFill 0.3s forwards ease-in-out;
}

.highlight-show-white {
  animation: highlightWhiteFill 0.3s forwards ease-in-out;
}

.perspective-blend-in{
  animation: perspectiveBlendIn 1s forwards ease-in-out;
}

.profile-card{
  background: white;
  box-shadow: #0A254022 0 0.5rem 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.75rem;
  position: absolute;
  right: 2rem;
  align-items: center;
  justify-content: flex-start;
}

.profile-image{
  width: 80px;
  height: 80px;
  margin-right: 0.5rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.round-icon-container{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  i{
    color: white;
    font-size: 2rem;
  }
}


// case study styles

.mobile-wrapper{
  width: calc(100% - 2rem);
  padding: 0 1rem;
}

.usp-icon-wrapper{
  border-radius: 100px;
  height: 45px;
  min-width: 45px;
  max-width: 45px;
  margin-right: 2rem;
  i{
    color: white;
    font-size: 1.5rem;
  }
}

.usp-container {
  gap: 0.5rem;
}

.first-usp{
  border-radius: 1rem 0 0 1rem;
  background: var(--light-pastel-pink);
  width: 33%;
  .usp-icon-wrapper{
    background: var(--pastel-pink);
  }
}

.second-usp{
  border-radius: 0;
  background: var(--light-pastel-accent);
  width: 34%;
  .usp-icon-wrapper{
    background: var(--pastel-accent);
  }
}

.third-usp{
  border-radius: 0 1rem 1rem 0;
  background: var(--light-pastel-violet);
  width: 33%;
  .usp-icon-wrapper{
    background: var(--pastel-violet);
  }
}

@media (max-width: 768px){

  .first-usp{
    border-radius: 2rem 2rem 0 0;
    width: 100%;
  }

  .second-usp{
    border-radius: 0;
    width: 100%;
  }

  .third-usp{
    border-radius: 0 0 2rem 2rem;
    width: 100%;
  }
}

.long-text{
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 300;
  color: var(--dark-marine);
}

.quote-text{
  color: var(--dark-marine);
  font-weight: 400;
  font-size: 1rem;
  line-height: 2rem;
}

.quote-author{
  font-size: 1rem;
  font-weight: 700;
  color: var(--dark-marine);
}

.quote-author-position{
  font-size: 1rem;
  font-weight: 700;
  color: var(--dark-marine);
}

.quote-icon{
  position: absolute;
  top: -2rem;
  right: -1rem;
  color: var(--light-grey);
  font-size: 5rem;
  z-index: -1;
}

.quote-icon-card{
  position: absolute;
  top: 0;
  right: 0.5rem;
  color: #4d4d4d33;
  font-size: 5rem;
}

.display-video {
  width: 860px;
  height: 484px;
  overflow: hidden;
  border-radius: 2rem;
  background-color: #6b6b6b;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


}

@media (max-width: 1080px){
  .display-video {
    width: 600px;
    height: 360px;
  }
}

@media (max-width: 600px){
  .display-video {
    width: 300px;
    height: 180px;
  }
}

.display-video-about{
  width: 100%;
  min-height: 200px;
  overflow: hidden;
  border-radius: 2rem;
  background-color: #6b6b6b;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.case-study-logo{
  max-height: 50px;
  margin-bottom: 2rem;
}

@media (max-width: 768px){
  .case-study-logo{
    max-height: 35px;
  }
}

.smaller-logo{
  img{
    height: 35px;
  }
}

.active-menupoint{
  color: var(--primary) !important;
  font-weight: 600;

  .nav-arrow-icon{
    fill: var(--primary) !important;
  }
  .nav-sub-button{
    color: black;
  }
}
.nav-arrow-icon {
  transition: 0.3s ease-in-out !important;
  transform: rotate(0deg);

}
.rotated {
  transform: rotate(-180deg);
}

.sub-header{
  font-size: 1.75rem;
  padding: 1rem 0;
  font-weight: 300;
  color: var(--medium-grey);
}

.usp-icon{
  font-size: 3rem;
  align-self: center;
}

.usp-header{
  font-size: 1.25rem;
  font-weight: 600;
}

.usp-card{
  width: 330px;
  p{
    font-weight: 400 !important;
  }
}

.backdrop-icon{
  font-size: 16rem;
  position: absolute;
  right: -25%;
  top: 5%;
  color: var(--medium-grey);
  opacity: 0.1;
}

@media (max-width: 600px){
  .usp-card{
    width: 100%;
    margin: 0.5rem 0;
  }
}
