//essential classes to style layout

//flex classes
.flex {
  display: flex;
  position: relative;
}

.row {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

.row-wrap{
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
}

.row-wrap-start-end {
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 1rem;
  align-items: flex-end;
}

.row-wrap-gap-lg {
  column-gap: 2rem;
  row-gap: 2rem;
}

.row-wrap-gap-sm {
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.column {
  flex-direction: column;
}

.column-reverse {
  flex-direction: column-reverse;
}

.end{
  justify-content: flex-end;
  align-items: center;
}

.end-end {
  justify-content: flex-end;
  align-items: flex-end;
}

.start{
  justify-content: flex-start;
  align-items: flex-start;
}

.start-center{
  justify-content: flex-start;
  align-items: center;
}

.center-start{
  justify-content: center;
  align-items: flex-start;
}

.center-stretch{
  justify-content: center;
  align-items: stretch;
}

.start-baseline{
  justify-content: flex-start;
  align-items: baseline;
}

.center{
  justify-content: center;
  align-items: center;
}

.center-start {
  justify-content: center;
  align-items: flex-start;
}

.flex-space-between{
  justify-content: space-between;
  align-items: flex-start;
}

.flex-space-between-center {
  justify-content: space-between;
  align-items: center;
}

.space-around {
  justify-content: space-around;
  align-items: flex-start;
}

.full-flex{
  flex: 1;
}

.gap {
  gap: 1rem;
}

.gap-lg {
  gap: 2rem;
}

.gap-xl {
  gap: 4rem;
}

.gap-sm {
  gap: 0.5rem;
}

.wrap {
  flex-wrap: wrap;
}

//height and width classes
.height-100 {
  height: 100%;
  box-sizing: border-box;
}

.height-50 {
  height: 50%;
  box-sizing: border-box;
}

.width-100 {
  width: 100%;
  box-sizing: border-box;
}

.width-80 {
  width: 80%;
  box-sizing: border-box;
}

.width-66 {
  width: calc(100% / 3 * 2)
}

.width-50 {
  width: 50%;
  box-sizing: border-box;
}

.width-50-gap {
  width: calc(50% - 1rem);
  box-sizing: border-box;
}

.width-33 {
  width: calc(100% / 3)
}

.width-25 {
  width: 25%;
}

.stretch-100 {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.cap-width {
  max-width: 1080px;
}

.cap-width-sm {
  max-width: 915px;
}

//z-index classes

.z-index-1 {
  z-index: 1;
}

//grid classes

.grid{
  display: grid;
  padding-bottom: 1rem;
  padding-right: 0.5rem;
  grid-auto-flow: column;
  grid-template-columns: repeat(12, 1fr);
  -moz-column-gap: 0.5rem;
  column-gap: 1rem;
  row-gap: 1rem;
}

.grid-flow{
  grid-auto-flow: inherit !important;
}

.grid-item {
  grid-column: span 12;
}

.grid-item-9{
  grid-column: span 9;
}

.grid-item-8{
  grid-column: span 8;
}

.grid-item-6{
  grid-column: span 6;
}

.grid-item-5{
  grid-column: span 5;
}

.grid-item-4{
  grid-column: span 4;
}

.grid-item-3{
  grid-column: span 3;
}

.grid-item-2{
  grid-column: span 2;
}

.grid-item-1{
  grid-column: span 1;
}



.content-width{
  max-width: 1440px;
}

.overflow-hidden{
  overflow: hidden;
}

.overflow-clip {
  overflow: clip;
}

@media(max-width: 768px){
  .content-width{
    max-width: 95%;
  }

  .responsive-grid{
    grid-auto-flow: row; /* Change grid flow to row */
    grid-template-columns: 1fr; /* Set single column layout */
    row-gap: 1rem; /* Adjust spacing between items */
    column-gap: 0; /* No gap between columns */
  }
}

//this should always be at the bottom of the stylesheet since its purpose is to remove the grid layout
.ungrid{
  grid: none !important;
}
