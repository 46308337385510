//adds some fun animations

.delay-0-2s{
  animation-delay: 0.2s !important;
}

.delay-0-3s{
  animation-delay: 0.2s !important;
}

.delay-0-4s{
  animation-delay: 0.4s !important;
}

.delay-0-5s{
  animation-delay: 0.5s !important;
}

.delay-0-6s{
  animation-delay: 0.6s !important;
}

.delay-0-8s{
  animation-delay: 0.8s !important;
}

.delay-1s{
  animation-delay: 1s !important;
}

.delay-1-5s{
  animation-delay: 1.5s !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fast-fade-in{
  animation: fadeIn 0.2s forwards ease-in-out;
}

.medium-fade-in{
  animation: fadeIn 0.5s forwards ease-in-out;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5) rotate(330deg);
    opacity: 0;
    filter: blur(0);
  }
  50% {
    transform: scale(1.05) rotate(365deg);
    opacity: 1;
    filter: blur(6px);
  }
  70% {
    transform: scale(0.95) rotate(360deg);
    opacity: 1;
    filter: blur(2px)
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    filter: blur(5px);
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    filter: blur(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeftGrayScale {
  0% {
    filter: blur(5px) grayscale(0.5) brightness(0.8);
    transform: translateX(-20%);
    opacity: 0;
  }
  100% {
    filter: blur(0) grayscale(1) brightness(1.2);
    transform: translateX(0);
    opacity: 1;
  }
}

.cursorClickAnimation{
  animation: clickAnimation 1s forwards ease-in-out;
}

.colorChangeAnimation{
  animation: colorChangeAnimation 1s forwards ease-in-out;
}

@keyframes clickAnimation {
  0%{
    transform: scale(1);
  }
  30%{
    transform: scale(1)
  }
  40%{
    transform: scale(1.1);
  }
  50%{
    transform: scale(0.9);
  }
  60%{
    transform: scale(1)
  }
  100%{
    transform: scale(1);
  }
}

@keyframes colorChangeAnimation {
  0%{
    filter: hue-rotate(0deg) brightness(1);
  }

  40%{
    filter: hue-rotate(0deg) brightness(1);
  }
  50% {
    filter: hue-rotate(0deg) brightness(1);
  }
  60%{
    filter: hue-rotate(20deg) brightness(0.8);
  }
  100%{
    filter: hue-rotate(20deg) brightness(0.8);
  }
}

@keyframes slideInFromBelow {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  80%{
    transform: translateY(0);
    opacity: 0.8;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes perspectiveBlendIn{
  0% {
    transform: translate(-3rem, 3rem) scale(1.2);
    opacity: 0;
    filter: blur(10px);
  }

  100% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes rotateInWithSpin{
  0% {
    transform: rotate(270deg) scale(0.6);
    opacity: 0;
    filter: blur(0px);
  }

  80% {
    transform: rotate(340deg) scale(0.95);
    opacity: 0.75;
    filter: blur(6px);
  }
  /*
    50%{
    transform: rotate(360deg) scale(0.8);
    opacity: 0.75;
    filter: blur(6px);
  }

  90%{
    transform: rotate(365deg) scale(1.05);
    opacity: 1;
    filter: blur(2px);
  }
  */

  100% {
    transform: rotate(360deg) scale(1);
    opacity: 1;
    filter: blur(0px);
  }
}

@keyframes appearFromCenterToTopRight {
  0%{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, 50%) scale(0.25);
    filter: blur(4px);
    opacity: 0;
  }
  100%{
    position: absolute;
    top: 1rem;
    right: 1rem;
    transform: translate(0, 0) scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes appearFromCenterToBottomLeft {
  0%{
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.25);
    filter: blur(4px);
    opacity: 0;
  }
  100%{
    position: absolute;
    bottom: 4rem;
    left: 4rem;
    transform: translate(0, 0) scale(1);
    filter: blur(0px);
    opacity: 1;
  }
}

.reveal-image{
  --s: 150px; /* the image size */

  width: var(--s);
  box-sizing: border-box;
  object-fit: cover;
  cursor: pointer;
  transition: .5s;
  height: 125px;
  object-position: top;
  padding-bottom: var(--s);
  background: white;
}

@keyframes revealFromTop {
  0% {
    //transform: rotate(30deg) scale(0.9);
    padding-bottom: 125px;
  }
  100% {
    //transform: rotate(0deg) scale(1);
    padding-bottom: 0;
  }
}

@keyframes expandFromInside {
  0%{
    transform: scaleX(0);
    opacity: 0;
    filter: blur(0);
  }

  70%{
    transform: scaleX(0.9);
    opacity: 0.9;
    filter: blur(4px);
  }

  100%{
    transform: scaleX(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes highlightReverseFill {
  0%{
    background: rgba(0, 200, 190, 0.31);
  }

  90%{
    background: #ffffff00;
  }

  100%{
    background: #ffffff00;
  }
}

@keyframes highlightWhiteFill {
  0%{
    background: rgba(0, 200, 190, 0.31);
  }

  90%{
    background: #ffffff;
  }

  100%{
    background: #ffffff;
  }
}


//Crazy carousel animations

.crazy-carousel-1{
  animation: crazyCarousel-1 20s infinite;
  bottom: 90%;
  opacity: 0;
  transform: scale(0.3);
}

.crazy-carousel-2{
  animation: crazyCarousel-2 20s infinite;
  bottom: -25%;
  opacity: 0;
  transform: scale(0.4);
}

.crazy-carousel-3{
  animation: crazyCarousel-3 20s infinite;
  bottom: 0;
  opacity: 0.5;
  transform: scale(0.6);
}

.crazy-carousel-4{
  animation: crazyCarousel-4 20s infinite;
  bottom: 25%;
  opacity: 0.75;
  transform: scale(0.8);
}

.crazy-carousel-5{
  animation: crazyCarousel-5 20s infinite;
  bottom: 50%;
  opacity: 1;
  transform: scale(1);
}

.crazy-carousel-6 {
  animation: crazyCarousel-6 20s infinite;
  bottom: 75%;
  opacity: 0.5;
  transform: scale(0.6);
}


@keyframes crazyCarousel-1 {
  0%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  17%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  33%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  50%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  66%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  83%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  100%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }
}

@keyframes crazyCarousel-2 {
  0%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  17%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  33%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  50%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  66%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  83%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  100%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }
}

@keyframes crazyCarousel-3 {
  0%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  17%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  33%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  50%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  66%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  83%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  100%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }
}

@keyframes crazyCarousel-4 {
  0%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  17%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  33%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  50%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  66%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  83%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  100%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }
}

@keyframes crazyCarousel-5 {
  0%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  17%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  33%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  50%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  66%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  83%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  100%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes crazyCarousel-6 {
  0%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }

  17%{
    bottom: 90%;
    opacity: 0;
    transform: scale(0.3);
  }

  33%{
    bottom: -25%;
    opacity: 0;
    transform: scale(0.4);
  }

  50%{
    bottom: 0;
    opacity: 0.5;
    transform: scale(0.6);
  }

  66%{
    bottom: 25%;
    opacity: 0.75;
    transform: scale(0.8);
  }

  83%{
    bottom: 50%;
    opacity: 1;
    transform: scale(1);
  }

  100%{
    bottom: 75%;
    opacity: 0.5;
    transform: scale(0.6);
  }
}


// mobile logo rotation

.logo-carousel-1{
  animation: logoCarousel-1 20s infinite;
  position:absolute !important;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem !important;
}

.logo-carousel-2{
  animation: logoCarousel-2 20s infinite;
  position:absolute !important;
  opacity: 0;
  left: 120%;
  transform: translateX(0);
  top: 1rem !important;

}

.logo-carousel-3{
  animation: logoCarousel-3 20s infinite;
  position:absolute !important;
  opacity: 0;
  left: 120%;
  transform: translateX(0);
  top: 1rem !important;

}

.logo-carousel-4{
  animation: logoCarousel-4 20s infinite;
  position:absolute !important;
  opacity: 0;
  left: -20%;
  transform: translateX(0);
  top: 1rem !important;

}

@keyframes logoCarousel-1 {
   0%{
     opacity: 1;
     left: 50%;
     transform: translateX(-50%);
   }

  20%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  40%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  60%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  80%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  100%{
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }
}

@keyframes logoCarousel-2 {
  0%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  20%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  40%{
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);

  }

  60%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  80%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  100%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }
}

@keyframes logoCarousel-3 {
  0%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  20%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  40%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);

  }

  60%{
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }

  80%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  100%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }
}

@keyframes logoCarousel-4 {
  0%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }

  20%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  40%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  60%{
    opacity: 0;
    left: 120%;
    transform: translateX(0);
  }

  80%{
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
  }

  100%{
    opacity: 0;
    left: -20%;
    transform: translateX(0);
  }
}

