
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
@import "stylesheets/colors";
@import "stylesheets/layout";
@import "stylesheets/animations";
@import "stylesheets/page-styles";
@import "stylesheets/fonts";
@import "stylesheets/innospire-util-styles";
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://cdn.materialdesignicons.com/7.2.96/css/materialdesignicons.min.css');

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-public-new-primary: mat.define-palette(mat.$indigo-palette);
$frontend-public-new-accent: mat.define-palette(mat.$grey-palette, 700);

// The warn palette is optional (defaults to red).
$frontend-public-new-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-public-new-theme: mat.define-light-theme((
  color: (
    primary: $frontend-public-new-primary,
    accent: $frontend-public-new-accent,
    warn: $frontend-public-new-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-public-new-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
}


html, body { height: 100%; }
body { margin: 0; }

body, button{
  font-family: Montserrat, sans-serif;
}

div {
  box-sizing: border-box;
}

:root {
  font-size: 15px;
  font-weight: 500;
}

@media (max-width: 960px) {
  :root {
    font-size: 13px;
  }
}

p, h4, h3, h2, h1{
  margin:0;
}

button {
  border: none;
  cursor: pointer;
  font-weight: 500;
}

a {
  color: var(--primary);
  font-weight: 600;
  text-decoration: none;

  &:hover {
    transition: color 250ms;
    color: var(--very-dark-teal);
  }
}

#dialog-wrapper {
  padding: 3rem 2rem 2rem;
}

.invalid-text {
  font-size: 0.865rem;
  color: var(--dark-accent) !important;
  margin-left: 0.5rem;
  margin-bottom: 0.25rem;
}


//General styles
//The following styles should be in general and if it is reasonable. Only in niche cases component based, granular styling should be used
//If during the development useful classes pop up feel free to add them to the list

//inputs
input, select {
  height: 2.5rem !important;
  border: none;
  font-size: 1rem !important;
  padding: 0 1rem !important;
  background-color: var(--very-light-grey) !important;
  border-radius: 0.5rem !important;
  outline-color: var(--medium-light-grey) !important;
}

.large-input{
  height: 3rem !important;
  font-size: 1.25rem !important;
  padding: 0 1rem !important;
}

.invalid-input {
  border: 1px solid var(--dark-accent) !important;
  border-radius: 0.5rem;
}


//general padding classes
.padding{
  padding: 1rem !important;
}

.padding-sm{
  padding: 0.5rem !important;
}

.padding-lg{
  padding: 2rem !important;
}

.padding-xl{
  padding: 4rem !important;
}

.padding-vertical{
  padding: 1rem 0 !important;
}

.padding-vertical-sm {
  padding: 0.5rem 0 !important;
}

.padding-vertical-lg{
  padding: 2rem 0 !important;
}

.padding-vertical-xl{
  padding: 4rem 0 !important;
}

.padding-horizontal{
  padding: 0 1rem !important;
}

.padding-horizontal-sm{
  padding: 0 0.5rem !important;
}

.padding-horizontal-lg{
  padding: 0 2rem !important;
}

.padding-horizontal-xl{
  padding: 0 4rem !important;
}

.padding-left{
  padding-left: 1rem !important;
}

.padding-left-lg{
  padding-left: 2rem !important;
}

.padding-left-xl{
  padding-left: 4rem !important;
}

.padding-left-sm{
  padding-left: 0.5rem !important;
}

.padding-right{
  padding-right: 1rem !important;
}

.padding-right-lg{
  padding-right: 2rem !important;
}

.padding-right-xl{
  padding-right: 4rem !important;
}

.padding-right-sm{
  padding-right: 0.5rem !important;
}

//content padding to not overlap with navbar
.content-padding {
  padding-top: 6rem;
  padding-bottom: 2rem;
}


//usually used inside of buttons
.offset-padding{
  padding: 0.5rem 2rem !important;
}

.remove-padding{
  padding: 0 !important;
}

//general margin styling
.margin{
  margin: 1rem !important;
}

.margin-sm{
  margin: 0.5rem !important;
}

.margin-lg{
  margin: 2rem !important;
}

.margin-vertical{
  margin: 1rem 0 !important;
}

.margin-vertical-sm{
  margin: 0.5rem 0 !important;
}

.margin-horizontal{
  margin: 0 1rem !important;
}

.margin-top {
  margin-top: 1rem;
}

.margin-bottom{
  margin-bottom: 1rem !important;
}

.margin-bottom-sm{
  margin-bottom: 0.5rem !important;
}

.margin-bottom-lg{
  margin-bottom: 2rem !important;
}

.margin-right{
  margin-right: 1rem !important;
}

.margin-right-lg{
  margin-right: 2rem !important;
}

.margin-right-sm{
  margin-right: 0.5rem !important;
}

.margin-left {
  margin-left: 1rem !important;
}


.bottom-margin-for-children > * {
  margin-bottom: 1rem;
}

.remove-margin{
  margin: 0 !important;
}

//text styling classes

.small-text{
  font-size: 0.75rem;
}

.large-text{
  font-size: 1.5rem;
}

.huge-text{
  font-size: 2rem;
}

.extra-slim-text{
  font-weight: 300;
}

.slim-text {
  font-weight: 400;
}

.default-weight-text {
  font-weight: 500;
}

.bold-text{
  font-weight: 600;
}

.extra-bold-text {
  font-weight: 700;
}

.start-text{
  text-align: start;
}

.center-text{
  text-align: center;
}

.no-wrap-text{
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  line-height: normal;
  width: fit-content;
}


//text and background color classes

.color-light-grey{
  color: var(--light-grey);
}

.light-text{
  color: var(--medium-grey);
}

.color-regular{
  color: var(--dark-grey)
}

.color-primary{
  color: var(--primary);
}

.color-accent{
  color: var(--accent);
}

.color-white{
  color: white;
}

.color-teal{
  color: var(--dark-teal);
}

.color-marine{
  color: var(--dark-marine);
}

.color-peach{
  color: var(--peach-accent);
}

.color-violet{
  color: var(--violet-accent);
}

.color-yellow-highlight{
  color: var(--yellow-highlight);
}

.color-pastel-accent {
  color: var(--pastel-accent);
}

.color-pastel-pink {
  color: var(--pastel-pink);
}

.color-pastel-violet {
  color: var(--pastel-violet);
}

.background-primary{
  background: var(--primary);
}

.background-light-primary{
  background: var(--light-primary);
}

.background-accent{
  background: var(--accent);
}

.background-violet{
  background: var(--violet-accent);
}

.background-marine{
  background: var(--dark-marine);
}

.background-peach{
  background: var(--peach-accent);
}

.background-light-peach{
  background: var(--light-peach-accent);
}

.background-pastel-accent {
  background: var(--light-pastel-accent)
}

.background-pastel-pink {
  background: var(--light-pastel-pink)
}

.background-pastel-violet {
  background: var(--light-pastel-violet)
}

.background-medium-grey{
  background: var(--medium-grey);
}

.background-white{
  background: white;
}

.background-night{
  background: var(--night-grey);
}

.background-light-teal{
  background: var(--very-light-teal);
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.peach-text{
  color: var(--peach-accent);
}

.violet-text{
  color: var(--violet-accent);
}

//more specific text styles

.page-header{
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.3;
  margin: 0.5rem 0;
  color: var(--dark-marine);
}

.big-section-header{
  font-size: 3rem;
  font-weight: 600;
  color: var(--dark-marine);
}

.medium-section-header {
  font-size: 2rem;
  font-weight: 600;
  color: var(--dark-marine);
}

.small-info-header{
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--dark-marine);
}

.more-info-text{
  font-size: 1rem;
  color: var(--medium-grey);
  text-transform: uppercase;
  font-weight: 200;
}

.marine-text{
  color: var(--dark-marine);
  font-weight: 300;
  font-size: 1rem;
}

.regular-text{
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  margin: 0.25rem 0;
  color: var(--dark-grey);
}

.small-primary-header {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--dark-teal);
}

//more specific text styles

.page-header{
  font-size: 4rem;
  font-weight: 600;
  margin: 0.5rem 0;
  color: var(--dark-marine);
}

.big-section-header{
  font-size: 3rem;
  font-weight: 600;
  color: var(--dark-marine);
}

@media(max-width: 960px){
  .page-header{
    font-size: 3rem;
    line-height: 4.5rem;
    //width: 100%;
    text-align: center;
  }
  .big-section-header{
    font-size: 2.25rem;
    line-height: 4rem;
    //width: 100%;
    text-align: center;
  }
}

.small-info-header{
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--dark-marine);
}

.more-info-text{
  font-size: 1rem;
  color: var(--medium-grey);
  text-transform: uppercase;
  font-weight: 200;
}

.marine-text{
  color: var(--dark-marine);
  font-weight: 300;
  font-size: 1rem;
}

.regular-text{
  font-size: 1rem;
  font-weight: 300;
  color: var(--dark-grey);
}



//button style classes
//flat buttons

.primary-button{
  background: var(--primary);
  border-radius: 3rem;
  color: white !important;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  span{
    color: white !important;
  }
  &:hover {
    background-color: var(--dark-teal);
    transition: background-color 250ms;
  }
}

.accent-button{
  background: var(--accent) ;
  border-radius: 3rem ;
  color: white !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span{
    color: white !important;
  }
  &:hover {
    background-color: var(--dark-accent);
    transition: background-color 250ms;
  }
}

.dark-flat-button{
  background: var(--dark-grey) ;
  border-radius: 3rem ;
  color: white !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span{
    color: white !important;
  }
}

.very-dark-flat-button{
  background: var(--very-dark-grey) ;
  border-radius: 3rem ;
  color: white !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span{
    color: white !important;
  }
}

.light-flat-button{
  background: var(--medium-light-grey) ;
  border-radius: 0.5rem ;
  color: var(--dark-grey) ;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
}

.very-light-flat-button{
  background: var(--light-grey) ;
  border-radius: 3rem ;
  color: var(--medium-grey) !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;

  &:hover {
    background-color: var(--medium-light-grey);
    transition: background-color 250ms;
  }
}

.white-flat-button{
  background: white ;
  border-radius: 3rem ;
  color: var(--dark-grey) ;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
}

.dark-teal-flat-button {
  background: var(--dark-teal);
  border-radius: 3rem;
  color: white;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  transition: background-color 250ms;

  &:hover {
    background-color: var(--very-dark-teal);
  }
}

//stroked buttons

.dark-stroked-button{
  border-radius: 3rem ;
  background: none;
  border: 1px solid var(--dark-grey);
  color: var(--dark-grey) ;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;

  &:hover {
    background: rgba(49, 49, 49, 0.1);
    border-color: var(--very-dark-grey);
    color: var(--very-dark-grey) !important;
    transition: all 250ms;
  }
}

.light-stroked-button{
  border-radius: 3rem;
  background: none;
  border: 1px solid var(--medium-grey);
  color: var(--medium-grey) !important;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  span {
    color: var(--medium-grey) !important;
  }

  &:hover {
    background: rgba(77, 77, 77, 0.1);
    border-color: var(--dark-grey);
    color: var(--dark-grey) !important;
    transition: all 250ms;
  }
}

.white-stroked-button{
  border-radius: 3rem ;
  background: none;
  border: 1px solid white ;
  color: white !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span {
    color: white !important;
  }
}


.primary-stroked-button{
  border-radius: 3rem ;
  background: none;
  border: 1px solid var(--primary) ;
  color: var(--primary) !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span {
    color: var(--primary) !important;
  }

  &:hover {
    background: rgba(0, 152, 144, 0.05);
    border-color: var(--dark-teal);
    color: var(--dark-teal) !important;
    transition: all 250ms;
  }
}

.accent-stroked-button{
  background: white ;
  border-radius: 3rem ;
  border: 1px solid var(--accent) ;
  color: var(--accent) !important;
  padding: 0.75rem 2rem ;
  font-size: 1rem ;
  span{
    color: var(--accent) !important;
  }

  &:hover {
    background: var(--light-pastel-accent);
    border-color: var(--dark-accent);
    color: var(--dark-accent) !important;
    transition: all 250ms;
  }
}

//scale buttons for mobile view
@media (max-width: 600px) {
  .accent-button, .primary-button, .dark-flat-button,
  .very-dark-flat-button, .light-flat-button, .very-light-flat-button,
  .white-flat-button, .dark-stroked-button, .light-stroked-button,
  .white-stroked-button, .primary-stroked-button, .dark-teal-flat-button, .accent-stroked-button {
    padding: 1rem 2rem;
    font-size: 1.25rem;
  }
}

//blank buttons

.blank-button{
  outline: 0 rgba(0, 0, 0, 0) ;
  border: 0 solid rgba(0,0,0,0) ;
  padding: 1rem 2rem;
  color: var(--dark-grey);
  background-color: rgba(0,0,0,0);
  border-radius: 1rem;
  font-size: 1.75rem;
  cursor: pointer;
  i{
    margin-right: 1rem;
  }
}

.blank-button:hover {
  transition: all 0.2s;
  background-color: rgba(0,0,0,0.1);
}

.blank-button:active {
  background-color: rgba(0,0,0,0.2);
}

.blank-button-small{
  outline: 0 rgba(0, 0, 0, 0) ;
  border: 0 solid rgba(0,0,0,0) ;
  padding: 0.75rem 2rem;
  color: var(--dark-grey);
  font-weight: 600;
  background-color: rgba(0,0,0,0);
  border-radius: 100px;
  font-size: 1rem;
  cursor: pointer;
  i{
    margin-right: 1rem;
  }
}

.blank-button-small:hover {
  transition: all 0.2s;
  background-color: rgba(0,0,0,0.1);
}

.blank-button-small:active {
  background-color: rgba(0,0,0,0.2);
}

//icon buttons

.clean-icon-button{
  display: flex;
  aspect-ratio: 1;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  font-size: 1.75rem;
  background: none;
  border-radius: 100%;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background: rgba(0, 0, 0, 0.15);
  }
}

.dark-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 3rem;
  background: var(--very-dark-grey);
  color: white;
  min-height: 3rem;
  min-width: 3rem;
  padding: 0;

  &:hover {
    transition: background 250ms;
    background: var(--dark-grey);
  }

  &:active {
    transition: background 250ms;
    background: var(--medium-grey);
  }
}

.light-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 3rem;
  border: 1px solid var(--very-dark-grey);
  background: var(--light-grey);
  color: var(--very-dark-grey);
  min-height: 3rem;
  min-width: 3rem;
  padding: 0;

  &:hover {
    transition: background 250ms;
    background: var(--medium-light-grey);
  }

  &:active {
    transition: background 250ms;
    background: var(--medium-grey);
  }
}

.primary-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 3rem;
  border: 1px solid var(--dark-teal);
  background: transparent;
  color: var(--dark-grey);
  min-height: 3rem;
  min-width: 3rem;
  padding: 0;

  &:hover {
    transition: background 250ms;
    background: rgba(67, 217, 179, 0.2);
  }

  &:active {
    transition: background 250ms;
    background: rgba(67, 217, 179, 0.4);
  }
}

//opacity styles

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

//anchor styles

.grey-anchor {
  width: fit-content;
  font-weight: 600;
  color: var(--medium-grey);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transition: color 200ms;
    color: var(--dark-grey);
  }
}

.dark-grey-anchor {
  font-weight: 500;
  width: fit-content;
  color: var(--dark-grey);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transition: color 200ms;
    color: var(--very-dark-grey);
  }
}

.dark-grey-active-anchor{
  cursor: default;
  pointer-events: none;
  font-weight: 700;
  width: fit-content;
  color: var(--dark-grey);
  text-decoration: none;
}


.black-anchor {
  font-weight: 500;
  width: fit-content;
  color: #000;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    transition: color 200ms;
    color: var(--very-dark-grey);
  }
}


//custom scrollbar styling

::-webkit-scrollbar-track
{
  border-radius: 2px;
  background-color: var(--light-grey);
}

::-webkit-scrollbar
{
  width: 0.5rem;
  height: 0.5rem;
  background-color: var(--light-grey);
}

::-webkit-scrollbar-thumb
{
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: var(--medium-grey);
}

::-webkit-scrollbar-thumb:hover
{
  border-radius: 4px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: var(--dark-grey);
}


//specific styling for public pages

.card-soft-shadow{
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 8px 48px rgba(210, 221, 253, 0.4);
  padding: 1rem;
}

.card-shadow{
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 4px 15px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
}

.card-sharp-shadow {
  box-shadow: 0 0 8px rgba(112, 112, 112, 0.39);
  background: white;
  padding: 1rem;
  border-radius: 1rem;
}

.card-flat{
  background: var(--very-light-grey);
  border-radius: 1.5rem;
  padding: 1rem;
}

.highlight, .highlight-white {
  color: var(--primary);
  padding: 0.25rem 1.25rem;
  border-radius: 1.5rem;
  border: 1px solid var(--primary);
  position: relative;
}

.highlight::before, .highlight-white::before {
  content: '';
  border: 1px solid var(--primary);
  border-radius: 100px;
  height: 0.5rem;
  width: 0.5rem;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}

.highlight::before, .highlight-white::before {

}

@media (max-width: 600px) {
  .highlight, .highlight-white{
    border-radius: 1rem;
  }

  .highlight::before, .highlight-white::before {
    height: 0.375rem;
    width: 0.375rem;
    top: 0.5rem;
    right: 0.5rem;
  }
}

.filter-grayscale{
  filter: grayscale(100%) brightness(1.2);
  opacity: 0.8;
  transition: 0.3s ease-in-out;
}

.white-gradient-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgb(255, 255, 255) 90%, rgb(255, 255, 255) 100%);
  z-index: 1;
}

.step-text{
  font-size: 5rem;
  font-weight: 600;
}

.teal-container{
  background: var(--light-teal);
  border-radius: 1rem;
  position: relative;
}

.content-box-width{
  max-width: 380px;
}


.divider{
  margin: 0.5rem 0;
  height: 1px;
  width: 100%;
  background: var(--medium-light-grey);
}

.light-divider {
  margin: 0.5rem 0;
  height: 1px;
  width: 100%;
  background: var(--light-grey);
}

.dark-divider{
  margin: 0.5rem 0;
  height: 1px;
  width: 100%;
  background: var(--very-dark-grey);
}

.skeleton {
  height: 24rem;
  width: 20rem;
}


.description-link,
.description-link:hover {
  color: black !important;
  font-weight: 700;
}


@media(max-width: 600px) {
  .skeleton {
    width: 100%;
    height: 23rem;
  }
}

@media (max-width: 500px){
  .page-header{
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}
