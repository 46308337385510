//Montserrat Font

//weight 100
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Thin.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 100;
  src: url("../assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf");
}

//weight 200
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 200;
  src: url("../assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 200;
  src: url("../assets/fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf");
}

//weight 300
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Light.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/Montserrat/static/Montserrat-LightItalic.ttf");
}

//weight 400
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Italic.ttf");
}

//weight 500
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat/static/Montserrat-MediumItalic.ttf");
}

//weight 600
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf");
}

//weight 700
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf");
}

//weight 800
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 800;
  src: url("../assets/fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf");
}

//weight 900
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("../assets/fonts/Montserrat/static/Montserrat-Black.ttf");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: 900;
  src: url("../assets/fonts/Montserrat/static/Montserrat-BlackItalic.ttf");
}
